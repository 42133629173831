@tailwind base;
@tailwind components;
@tailwind utilities;

.telex-regular {
    font-family: "Montserrat", sans-serif;
    /* font-weight: 400; */
    font-style: normal;
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(106, 192, 106);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(106, 192, 106);
  }